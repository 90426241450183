@import url(https://fonts.googleapis.com/css2?family=Open+Sans:wght@300;400;600;700&display=swap);
@import url(https://fonts.googleapis.com/css2?family=PT+Sans:ital,wght@0,400;0,700;1,400;1,700&display=swap);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

/* @import url('https://fonts.googleapis.com/css2?family=Overpass:wght@100;200;400;700&display=swap'); */
/* #### Generated By: http://www.cufonfonts.com #### */
/* @import url('https://fonts.googleapis.com/css2?family=Comfortaa:wght@300;400;500;600;700&display=swap'); */
/* @font-face {
  font-family: 'Creattion Demo';
  font-style: normal;
  font-weight: normal;
  src: local('Creattion Demo'), url('./fonts/Creattion Demo.otf') format('otf');
} */
@font-face {
  font-family: 'Creattion Demo';
  src: local('Creattion Demo'),
    url(/static/media/CreattionDemo.fac8f063.otf) format('opentype');
}
/* 
@font-face {
  font-family: 'Gordita Regular Italic';
  font-style: normal;
  font-weight: normal;
  src: local('Gordita Regular Italic'),
    url('./fonts/Gordita Regular Italic.woff') format('woff');
}

@font-face {
  font-family: 'Gordita Thin';
  font-style: normal;
  font-weight: normal;
  src: local('Gordita Thin'), url('./fonts/Gordita Thin.woff') format('woff');
}

@font-face {
  font-family: 'Gordita Thin Italic';
  font-style: normal;
  font-weight: normal;
  src: local('Gordita Thin Italic'),
    url('./fonts/Gordita Thin Italic.woff') format('woff');
}

@font-face {
  font-family: 'Gordita Light';
  font-style: normal;
  font-weight: normal;
  src: local('Gordita Light'), url('./fonts/Gordita Light.woff') format('woff');
}

@font-face {
  font-family: 'Gordita Light Italic';
  font-style: normal;
  font-weight: normal;
  src: local('Gordita Light Italic'),
    url('./fonts/Gordita Light Italic.woff') format('woff');
}

@font-face {
  font-family: 'Gordita Medium';
  font-style: normal;
  font-weight: normal;
  src: local('Gordita Medium'),
    url('./fonts/Gordita Medium.woff') format('woff');
}

@font-face {
  font-family: 'Gordita Medium Italic';
  font-style: normal;
  font-weight: normal;
  src: local('Gordita Medium Italic'),
    url('./fonts/Gordita Medium Italic.woff') format('woff');
}

@font-face {
  font-family: 'Gordita Bold';
  font-style: normal;
  font-weight: normal;
  src: local('Gordita Bold'), url('./fonts/Gordita Bold.woff') format('woff');
}

@font-face {
  font-family: 'Gordita Bold Italic';
  font-style: normal;
  font-weight: normal;
  src: local('Gordita Bold Italic'),
    url('./fonts/Gordita Bold Italic.woff') format('woff');
}

@font-face {
  font-family: 'Gordita Black';
  font-style: normal;
  font-weight: normal;
  src: local('Gordita Black'), url('./fonts/Gordita Black.woff') format('woff');
}

@font-face {
  font-family: 'Gordita Black Italic';
  font-style: normal;
  font-weight: normal;
  src: local('Gordita Black Italic'),
    url('./fonts/Gordita Black Italic.woff') format('woff');
}

@font-face {
  font-family: 'Gordita Ultra';
  font-style: normal;
  font-weight: normal;
  src: local('Gordita Ultra'), url('./fonts/Gordita Ultra.woff') format('woff');
}

@font-face {
  font-family: 'Gordita Ultra Italic';
  font-style: normal;
  font-weight: normal;
  src: local('Gordita Ultra Italic'),
    url('./fonts/Gordita Ultra Italic.woff') format('woff');
} */
body,
div,
span,
applet,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
abbr,
acronym,
address,
big,
cite,
code,
del,
em,
img,
ins,
kbd,
q,
s,
samp,
small,
strike,
strong,
sub,
sup,
tt,
var,
b,
u,
i,
center,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
embed,
figure,
figcaption,
footer,
header,
menu,
nav,
output,
section,
summary,
time,
mark,
audio,
video {
  margin: 0;
  padding: 0;
  border: 0;

  /* font: inherit;
  font-family: 'Manrope', sans-serif; */
  /* line-height: 3.2rem; */
  /* word-spacing: 6px;
  letter-spacing: -2px; */
}

*,
*::before,
*::after {
  box-sizing: border-box;
}
h1 {
  font-size: 50px;
}
a {
  text-decoration: none;
  color: inherit;
  /* background-image: linear-gradient(120deg, #000000 0%, #000000 100%);
  background-repeat: no-repeat;
  background-size: 100% 0.03em;
  background-position: 0 88%;
  color: initial; */
}
body {
  background-color: rgb(253, 253, 253);
}

ul {
  list-style: none;
  padding: 0;
  margin: 0;
}

@keyframes fadeInUp {
  from {
    transform: translateY(60px);
    opacity: 0;
  }

  to {
    transform: translateY(0);
    opacity: 1;
  }
}

.fadeInUp {
  animation-name: fadeInUp;
  animation-duration: 0.2s;
  /* animation-fill-mode: both; */
}

